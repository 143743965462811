// confirm-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  styleUrls: ['./company-delete-dialog.component.scss'],
  template: `
    <div class="dialog-container">
      <h1 mat-dialog-title>Löschen bestätigen</h1>
      <div mat-dialog-content>
        <p>Sind Sie sicher, dass Sie dieses Unternehmen löschen möchten?</p>
      </div>
      <footer class="dialog-container__action-row">
        <button
          mat-stroked-button
          (click)="onDismiss()"
          class="button-secondary"
        >
          Abbrechen
        </button>
        <button class="button-primary" mat-flat-button (click)="onConfirm()">
          Unternehmen löschen
        </button>
      </footer>
    </div>
  `,
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
