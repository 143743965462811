import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AnalyticsDashboardComponent} from './features/analytics-dashboard/analytics-dashboard.component';

export const ANALYTICS_ROUTES = {
    MODULE: 'analytics',
    ROOT: '',
};

const ROUTES: Routes = [
    {
        path: ANALYTICS_ROUTES.ROOT,
        component: AnalyticsDashboardComponent,
    },
    {
        path: ANALYTICS_ROUTES.MODULE,
        component: AnalyticsDashboardComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class AnalyticsRoutingModule {
}
