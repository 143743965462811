<div class="dashboard">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Accounts</mat-card-title>
        </mat-card-header>
        <mat-card-content style="height: 20px"></mat-card-content>
        <mat-card-actions>
            <a mat-flat-button color="primary" [routerLink]="['/accounts']">Zu den Accounts</a>
        </mat-card-actions>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Unternehmen</mat-card-title>
        </mat-card-header>
        <mat-card-content style="height: 20px"></mat-card-content>
        <mat-card-actions class="actions">
            <a mat-flat-button color="primary" [routerLink]="['/companies']">Zu den Unternehmen</a>
        </mat-card-actions>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Bewerberprofile</mat-card-title>
        </mat-card-header>
        <mat-card-content style="height: 20px"></mat-card-content>
        <mat-card-actions>
            <a mat-flat-button color="primary" [routerLink]="['/applications']">Zu den Bewerberprofilen</a>
        </mat-card-actions>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Stellenausschreibungen</mat-card-title>
        </mat-card-header>
        <mat-card-content style="height: 20px"></mat-card-content>
        <mat-card-actions>
            <a mat-flat-button color="primary" [routerLink]="['/jobs']">Zu den Stellenauschreibungen</a>
        </mat-card-actions>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Statistiken</mat-card-title>
        </mat-card-header>
        <mat-card-content style="height: 20px"></mat-card-content>
        <mat-card-actions>
            <a mat-flat-button color="primary" [routerLink]="['/analytics']">Zu den Statistiken</a>
        </mat-card-actions>
    </mat-card>
</div>
