import {
    DEFAULT_API_VERSION,
    DEFAULT_API_VERSION_NUMERICAL,
    DEFAULT_READ_TIMEOUT,
    DEFAULT_WRITE_TIMEOUT,
    DEFAULTS,
    IEnvironment
} from './environment.default';

export const ENVIRONMENT: IEnvironment = {
    ...DEFAULTS,
    production: true,
    api: {
        ssl: true,
        gatewayBaseUrl: `https://api-v2.dev.nectanet-futurenet.actihosting.de`,
        gatewayVersion: 1,
        apiVersion: DEFAULT_API_VERSION ?? `v2`,
        apiVersionNumerical: DEFAULT_API_VERSION_NUMERICAL ?? 2,
        fileServerBaseUrl: `https://api-v2.dev.nectanet-futurenet.actihosting.de`,
        staticBaseUrl: `/assets/data`,
        readTimeout: DEFAULT_READ_TIMEOUT ?? 8000,
        writeTimeout: DEFAULT_WRITE_TIMEOUT ?? 8000
    }
};
