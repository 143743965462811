import { Component, Input } from '@angular/core';
import { IChartData } from '@app/core/interfaces';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'lca-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  @Input() data: IChartData;

  private chart: any;

  constructor() {}

  ngOnChanges() {

    if (!this.chart) {
      return;
    }

    this.chart.data.labels = this.getLabels();
    this.chart.data.datasets = this.getDatasets();

    this.chart.update();
  }

  getLabels(): string[] {
    return this.data.datasets.reduce((acc: string[], dataset: { label: string }) => [...acc, dataset.label], []);
  }

  getDatasets(): { data: number[], backgroundColor: string[] }[] {
    const colors = ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'];

    return [{
      data: this.data.datasets.map((dataset: { data: number[] }) => dataset.data.reduce((acc: number, data: number) => acc + data, 0)),
      backgroundColor: colors
    }]
  }

  ngAfterViewInit() {
    const ctx = document.getElementById('pie-chart') as HTMLCanvasElement;

    this.chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.getLabels(),
        datasets: this.getDatasets()
      }
    });
  }
}
