import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IChartData } from '@core/interfaces';
import {
  finalize,
  take,
} from 'rxjs/operators';
import { AnalyticsService } from '@app/core/api/analytics.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'lca-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsDashboardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly destroyed$: Subject<void> = new Subject<void>();
  public readonly loading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  granularity = 'day';
  granularityOptions = [
    { value: 'day', label: 'Tag' },
    { value: 'week', label: 'Woche' },
    { value: 'month', label: 'Monat' },
  ];

  public chartData: any;
  public histogramData: any;

  constructor(
    private readonly analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.load();

    this.dateRange.setValue({
      start: new Date(new Date().setDate(new Date().getDate() - 7)),
      end: new Date()
    });

    this.dateRange.valueChanges.subscribe(value => {
      if (value.start && value.end && value.start < value.end) {
        this.load();
      }
    });
  }

  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public reload(): void {
    if (!this.loading$.value) {
      this.load();
    }
  }

  public setGranularity(event: MatRadioChange): void {
    this.granularity = event.value;
    this.load();
  }

  private getDateRangeValues(): { startDate: string; endDate: string } {
    let startDate = this.dateRange?.value?.start || new Date(new Date().setDate(new Date().getDate() - 7));
    let endDate = this.dateRange?.value?.end || new Date();

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };
  }

  private load(): void {
    this.loading$.next(true);

    const { startDate, endDate } = this.getDateRangeValues();

    this.analyticsService
      .getChartData({
        startDate: startDate,
        endDate: endDate,
        granularity: this.granularity || 'day',
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp: IChartData) => {
        console.log('RESP', resp);

        this.chartData = resp;

        return resp;
      });

    this.analyticsService
      .getWeekHistogramData({
        startDate: startDate,
        endDate: endDate
      })
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe((resp: IChartData) => {
        console.log('RESP HISTO', resp);

        this.histogramData = resp;

        return resp;
      });
  }
}
