<div class="table-container">


  <!-- Header -->
  <header class="table-header">
    <mat-progress-bar
      [ngStyle]="{ visibility: (loading$ | async) ? 'visible' : 'hidden' }"
      mode="indeterminate"
    ></mat-progress-bar>
    <h1 class="mat-headline" role="heading">Statistiken</h1>

    <div class="table-toolbar" role="toolbar">

      <!-- Range picker -->
      <div class="table-filter">
        <mat-form-field>
          <mat-label>Eingabe eines Datumsbereichs</mat-label>
          <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
            <input matStartDate placeholder="Start date" formControlName="start">
            <input matEndDate placeholder="End date" formControlName="end">
          </mat-date-range-input>
          <mat-hint>TT/MM/YYYY – TT/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-radio-group [(ngModel)]="granularity" style="max-width: 100px; display: flex;" (change)="setGranularity($event)">
          <mat-radio-button *ngFor="let granularity of granularityOptions" [value]="granularity.value">
            {{ granularity.label }}
          </mat-radio-button>
        </mat-radio-group>

      </div>

      <!-- Refresh button -->
      <div class="table-action">
        <button
          mat-stroked-button
          (click)="reload()"
          aria-label="Aktualisieren List"
        >
          <mat-icon>autorenew</mat-icon>
          Aktualisieren
        </button>
      </div>
    </div>
  </header>

  <!-- Charts -->
  <div style="margin-top: 100px; display: flex; gap: 20px; flex-direction: row;" *ngIf="chartData">
    <lca-line-chart *ngIf="chartData" [data]="chartData" style="flex-grow: 3; background: white; display: flex; flex-direction: column; box-shadow: 3px 3px 15px -3px rgba(191,191,191,1); border-radius: 8px"></lca-line-chart>
    <lca-pie-chart *ngIf="chartData" [data]="chartData" style="flex-grow: 1; background: white; display: flex; flex-direction: column; box-shadow: 3px 3px 15px -3px rgba(191,191,191,1); border-radius: 8px"></lca-pie-chart>
  </div>
  <div style="margin-top: 20px; display: flex; gap: 20px; flex-direction: row;" *ngIf="histogramData">
    <lca-bar-chart *ngIf="histogramData" [data]="histogramData" style="flex-grow: 1; background: white; display: flex; flex-direction: column; box-shadow: 3px 3px 15px -3px rgba(191,191,191,1); border-radius: 8px"></lca-bar-chart>
  </div>
</div>
