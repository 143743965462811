import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@environment';
import { debounceTime, distinctUntilChanged, map, tap, timeout } from 'rxjs/operators';
import { FromApi, IChartData, Paginated } from '@core/interfaces';
import * as qs from 'qs'

interface Options {
    startDate: string;
    endDate: string;
    granularity: string;
}

interface WeekHistogramOptions {
    startDate: string;
    endDate: string;
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private readonly endpoint: string = `${ENVIRONMENT.api.gatewayBaseUrl}/api`;
    private readonly requestTimeout: number = ENVIRONMENT.api.writeTimeout;
    private readonly debounceTime: number = 1000;

    constructor(private readonly httpClient: HttpClient) {
    }

    public getChartData(options?: Options): Observable<IChartData> {
        const REQUEST_URL = `${this.endpoint}/analytics/chart-data`;
        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify(options, {
                encodeValuesOnly: true
            })
        });

        return this.httpClient
            .get<IChartData>(REQUEST_URL, {
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(value => {

                    value.datasets = value.datasets.map((dataset: { label: string, data: number[] }, index: number) => {

                        // If dataset.label === 'applicant' translate to 'bewerber'
                        if (dataset.label === 'applicant') {
                            dataset.label = 'bewerber';
                        }

                        return dataset;
                    });

                    return value satisfies IChartData
                }),
            );
    }

    public getWeekHistogramData(options?: WeekHistogramOptions): Observable<IChartData> {
        const REQUEST_URL = `${this.endpoint}/analytics/week-data`;
        const HTTP_PARAMS = new HttpParams({
            fromString: qs.stringify(options, {
                encodeValuesOnly: true
            })
        });

        return this.httpClient
            .get<IChartData>(REQUEST_URL, {
                withCredentials: true,
                params: HTTP_PARAMS
            })
            .pipe(
                debounceTime(this.debounceTime),
                timeout(this.requestTimeout),
                distinctUntilChanged(),
                map(value => {

                    value.datasets = value.datasets.map((dataset: { label: string, data: number[] }, index: number) => {

                        // If dataset.label === 'applicant' translate to 'bewerber'
                        if (dataset.label === 'applicant') {
                            dataset.label = 'bewerber';
                        }

                        return dataset;
                    });

                    return value satisfies IChartData
                }),
            );
    }
}
