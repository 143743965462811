import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'lca-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {
  @Input() data: { labels: string[], datasets: { label: string, data: number[] }[] };

  private chart: any;

  constructor() {}

  getLabels(): string[] {
    return this.data.labels;
  }

  getDatasets(): { label: string, data: number[] }[] {
    const colors = ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'];

    return this.data.datasets.map((dataset: { label: string, data: number[] }, index: number) => ({
      label: dataset.label,
      data: dataset.data,
      borderColor: colors[index],
      borderWidth: 3,
      tension: 0
    }));
  }

  ngOnChanges() {

    if (!this.chart) {
      return;
    }

    this.chart.data.labels = this.getLabels();
    this.chart.data.datasets = this.getDatasets();

    this.chart.update();
  }

  ngAfterViewInit() {
    const ctx = document.getElementById('line-chart') as HTMLCanvasElement;

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.getLabels(),
        datasets: this.getDatasets()
      }
    });
  }
}
